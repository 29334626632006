<template>
  <MDBContainer fluid class="login-page">
    <MDBRow>
      <MDBCol class="d-none d-md-block px-0">
        <div class="left-pane">
          <img src="../../assets/logo-2024.png" aria-hidden="true" alt="logo" />
          <div class="footnote">
            <h6 class="mb-0">Enjoying CreatorShield ? 🤞</h6>
            <p class="mb-0">
              Create a promotional video and get an epic earning boost >
            </p>
          </div>
        </div>
      </MDBCol>
      <MDBCol>
        <div class="d-flex justify-content-center align-items-center">
          <div class="card-container">
            <MDBCard class="shadow-2-strong" v-if="loadingSpinner">
              <MDBCardBody class="p-5 text-center">
                <MDBSpinner size="lg" />
              </MDBCardBody>
            </MDBCard>
            <MDBCard class="shadow-2-strong" v-else>
              <MDBCardBody class="p-5 text-center" v-if="isLinkValid">
                <h1 class="mb-5">Reset Password</h1>

                <div
                  class="form-outline mb-3"
                  style="
                    text-align: left;
                    padding-left: 20px;
                    padding-right: 20px;
                  "
                >
                  <MDBInput
                    label="Password"
                    type="password"
                    v-model="form.password"
                  />
                </div>
                <div
                  class="form-outline mb-3"
                  style="
                    text-align: left;
                    padding-left: 20px;
                    padding-right: 20px;
                  "
                >
                  <MDBInput
                    label="Confirm Password"
                    type="password"
                    v-model="confirmPassword"
                    :helper="!isSamePassword ? 'Password does not match!' : ''"
                  />
                </div>

                <button
                  @click="submit"
                  :disabled="isSubmitBtnDisabled"
                  class="btn btn-primary btn-lg btn-block submit-btn mt-5"
                >
                  Reset
                </button>
              </MDBCardBody>
              <MDBCardBody
                class="p-5 text-center invalid-link"
                v-if="!isLinkValid"
              >
                <em class="fa fa-unlink mb-3" />
                <h1>Invalid Link!</h1>
                <p>This link might have been expired. That's all we know :(</p>
              </MDBCardBody>
            </MDBCard>

            <p class="text-center mt-5">
              Back to
              <router-link
                :to="{ name: 'Login' }"
                custom
                v-slot="{ href, navigate }"
              >
                <a :href="href" class="pe-auto fw-bold" @click="navigate">
                  Sign in
                </a>
              </router-link>
            </p>
          </div>
        </div>
      </MDBCol>
    </MDBRow>
  </MDBContainer>
  <MDBToast
    v-model="toastObject.state"
    :delay="2000"
    autohide
    position="top-right"
    appendToBody
    stacking
    width="350px"
    :color="toastObject.color"
    text="white"
    :icon="toastObject.icon"
  >
    <template #title> </template>
    {{ toastObject.message }}
  </MDBToast>
</template>
<script setup>
import {
  MDBToast,
  MDBInput,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBSpinner,
} from "mdb-vue-ui-kit";
import axios from "axios";
import { ref, onBeforeMount, reactive, computed } from "vue";
import { useTitle } from "@vueuse/core";
import { useRoute } from "vue-router";

onBeforeMount(async () => {
  useTitle("Reset Password | CreatorShield");

  loadingSpinner.value = true;

    await axios
      .get("api/users/" + link + "/Reset")
      .then(function (response) {
        isLinkValid.value = true;
        if (response == undefined || response.status != 200) {
          isLinkValid.value = false;
        }
        loadingSpinner.value = false;
      })
      .catch(function () {
        isLinkValid.value = false;
        toastObject.value.state = true;
        toastObject.value.message = "An error occurred during request.";
        toastObject.value.color = "danger";
        toastObject.value.icon = "fas fa-exclamation-circle fa-lg me-2";
        loadingSpinner.value = false;
      });
});

const toastObject = ref({
  state: false,
  message: "",
  color: "",
  icon: "",
});
let loadingSpinner = ref(false);
const route = useRoute();
const link = route.params.link;

const form = reactive({
  password: "",
});

const confirmPassword = ref("");

const isSamePassword = computed(
  () =>
    form.password == confirmPassword.value &&
    form.password != "" &&
    confirmPassword.value != ""
);
const isSubmitBtnDisabled = computed(
  () => !isSamePassword.value || form.firstname == "" || form.lastname == ""
);

const isLinkValid = ref(true);

const submit = () => {
  let formData = new FormData();
  formData.append("link", link);
  formData.append("password", form.password);

  loadingSpinner.value = true;
  axios
    .post("api/users/resetpassword", formData)
    .then(function (response) {
      if (response.status == 200) {
        toastObject.value.title = "Success!";
        toastObject.value.state = true;
        toastObject.value.message = "Successfully submitted!";
        toastObject.value.color = "success";
        toastObject.value.icon = "fas fa-check fa-lg me-2";
        loadingSpinner.value = false;
      }
    })
    .catch(function () {
      toastObject.value.state = true;
      toastObject.value.title = "Error!";
      toastObject.value.message = "An error occurred during request";
      toastObject.value.color = "danger";
      toastObject.value.icon = "fas fa-exclamation-circle fa-lg me-2";
      loadingSpinner.value = false;
    });
};
</script>
<style>
@import "../../assets/Sailec-Font/fonts.css";
:root {
  --white: #ffffff;
  --primary: #fe632d;
  --secondary: #e2e6ef;
  --accent: #02061b; /* #252527 */
}
</style>
<style lang="scss" scoped>
.spinner-border {
    color: var(--primary);
}
.login-page {
  background-color: #f9f9f9;
  width: 100vw;
  height: 100vh;
  & .d-flex {
    height: 100vh;
  }
}
.card-container {
  min-width: 35vw;
  .card {
    border-radius: 1rem;
    background-color: white;
  }

  h1 {
    font-family: "Sailec Bold", sans-serif !important;
    color: var(--accent);
  }
  .forgot-password {
    font-family: "Sailec Bold", sans-serif;
    font-size: 12px;
    cursor: pointer;
  }

  a {
    font-family: "Sailec Bold", sans-serif;
    font-weight: bold;
    color: var(--accent);
  }
}

.left-pane {
  height: 100vh;
  background-image: url("../../assets/you-create-we-protect.png"),
    linear-gradient(90deg, #fe632d, #f63939);
  background-size: contain, cover;
  background-repeat: no-repeat, no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 25px;

  @media screen and (max-width: 1199px) {
    background-position: center;
  }

  img[alt~="logo"] {
    width: 200px;
  }
  .footnote {
    color: white;
    h4 {
      font-family: "Sailec Bold", sans-serif !important;
    }
  }
}
.invalid-link h1 {
  font-family: "Sailec Bold", sans-serif !important;
  color: var(--accent);
}
.invalid-link p {
  font-family: "Sailec Light", sans-serif !important;
  color: var(--accent);
}
.invalid-link .fa {
  font-size: 64px;
  color: var(--primary);
}

:deep(.form-outline .form-helper) {
  color: red;
}

.submit-btn {
  background-color: var(--primary);
}
</style>
